<template>
  <b-modal ref="search-ty-products-modal"
           id="search-ty-products-modal"
           hide-footer
           no-close-on-backdrop
           no-close-on-esc
           size="xl"
           title="Trendyol Ürün Arama"
  >
    <b-row>
      <b-col cols="3">
        <b-card>
          <b-row>
            <b-col>
              <b-img-lazy
                  v-if="index==0"
                  v-for="(image,index) in product.images"
                  :src="siteUrl+image.url"
                  :key="image.id"
                  rounded
                  width="150"
                  left class="mb-1 mb-sm-0"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              İsim: {{ product.name }} <br>
              Barkod: {{ product.barcode }} <br>
              Gramaj: {{ product.weight }} <br>
              Fiyat: {{ product.purchase_price }} <br>
              Stok: {{ product.quantity }} <br>
            </b-col>
          </b-row>

        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <b-row>
            <b-col cols="3">
              <b-form-input type="text" v-model="params.brandIds" placeholder="Marka Id'leri Giriniz (,)"/>
            </b-col>
            <b-col>
              <b-form-input
                  type="text"
                  @keyup.enter="getSearchProducts"
                  placeholder="Aranacak Kelime"
              />
            </b-col>
          </b-row>
        </b-card>

        <b-card>
          <b-row>
            <b-col
                v-for="(searchProduct,index) in searchProducts"
                :key="index"
                cols="4"
                class="border border-primary rounded"
            >
              <div
                  v-for="(variant,index1) in searchProduct.variants"
                  :key="index1"
              >
                <a :href="searchProduct.content.productUrl" target="_blank">
                  <b-img-lazy
                      :src="imgurl(searchProduct)"
                      rounded
                      width="150"
                      left class="mb-1 mb-sm-0"
                  />
                </a>

                {{ variant.barcode }} <br>
                <b>{{ searchProduct.content.title }}</b>
                <br>
                {{ searchProduct.content.brandName }} <br>
                {{ searchProduct.content.categoryName }} <br>
                <b>{{ searchProduct.content.buyboxPrice }} -- {{ searchProduct.content.suggestedPrice }}</b> <br>

                <div v-if="!variant.exist">
                  <b-form-input type="number" :value="1"/>
                  <b-button :disabled="variant.exist" variant="primary">Ürünü Ekle</b-button>
                </div>

              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import axios from '@/libs/axios'

export default {
  name: 'SearchProducts',
  data: () => ({
    storeUuid: null,
    product: {},
    params: {
      page: 1,
      pageSize: 100,
      searchTerm: '',
      name: '',
      brandIds: 1581250,
    },
    searchProducts: [],
  }),
  computed: {
    siteUrl() {
      return process.env.VUE_APP_IMG_URL
    },
  },
  methods: {
    imgurl(searchProduct) {
      const url = searchProduct.content.images[0].thumbnailUrl
      return url.replace('.jpg', '_org_zoom.jpg')
    },
    getSearchProducts($event) {
      this.params.searchTerm = $event.target.value
      this.params.name = $event.target.value
      axios.post(`/trendyol/inventory/${this.storeUuid}/searchproducts`, this.params)
          .then(res => {
            if (res.data.status === 'success') {
              this.searchProducts = res.data.content.content
            } else {
              alert(res.data.message)
            }
          })
    },
  },
  mounted() {
    this.$root.$on('search-ty-products-event', payload => {
      this.storeUuid = payload.storeUuid
      this.product = payload.product
    })
  },
}
</script>

<style scoped>

</style>
