<template>
  <div>
    <H3>Product Route Home</H3>
    <router-view/>
    <SearchProducts/>
  </div>
</template>

<script>
import SearchProducts from '@/views/trendyol/SearchProducts.vue'

export default {
  name: 'ProductHome',
  components: {SearchProducts},
}
</script>

<style scoped>

</style>
